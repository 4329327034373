import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { Page } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

interface ItemProps {
  title: string
  URL: string
}

export interface Props {
  languageCode: string
  title?: string
  articles: ItemProps[]
}

export const RecentArticles = memo(function RecentArticles({
  languageCode,
  title,
  articles,
}: Props) {
  if (!articles) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{useVocabularyData(title, languageCode)}</Title> : null}
      <List>
        {articles.slice(0, 5).map((item, index) => (
          <Item key={index}>
            <Page />
            <Anchor to={item.URL}>{item.title}</Anchor>
          </Item>
        ))}
      </List>
    </Container>
  )
})

const Container = styled(FlexBox)`
  border-bottom: 1px solid #dedede;
  padding: 2rem 0;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
`

const List = styled.ul``

const Item = styled.li`
  display: flex;
  margin-top: 1.5rem;
  padding-left: calc(18px + 0.75rem);
  position: relative;
  svg {
    position: absolute;
    top: -2px;
    left: 0;
  }
`

const Anchor = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.938rem;
  &:hover {
    text-decoration: underline;
  }
`
