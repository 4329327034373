import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  title?: string
  description?: string
  image?: ImageProps
  button?: ButtonProps
}

export const Paragraph = memo(function Paragraph({
  title,
  image,
  description,
  button,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : ''}
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {description ? (
        <Text dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {button ? <Button {...button} variant="primary" /> : null}
    </Container>
  )
})

const Container = styled.section`
  text-align: left;
  max-width: 1200px;
  margin: 4rem auto;
  &:last-child {
    margin-bottom: 0;
  }

  button {
    margin-top: 2rem;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 1.125rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  margin: 1.125rem auto;
  text-align: left;
  font-weight: bold;
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 2rem;
  a {
    text-decoration: underline;
  }
  p {
    margin: 1rem 0;
  }
  ul {
    margin: 1rem 0;
    padding: 0 1rem;
    list-style: initial;
  }
`
