import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Paragraph, Props as ParagraphProps } from './Paragraph'

export interface Props {
  paragraphs: ParagraphProps[]
}

export const Paragraphs = memo(function Paragraphs({ paragraphs }: Props) {
  if (!paragraphs) {
    return null
  }

  return (
    <Container>
      {paragraphs.map((item, index) => (
        <Paragraph key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  text-align: left;
`
