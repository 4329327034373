import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { Paragraphs } from 'app/components/Paragraphs'
import { RecentArticles } from 'app/components/RecentArticles'
import { SEO } from 'app/components/SEO'
import {
  CustomerReviews,
  Props as CustomerReviewsProps,
} from 'app/containers/CustomerReviews'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import {
  MainLeadGeneration,
  Props as MainLeadGenerationProps,
} from 'app/containers/MainLeadGeneration'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  mainLeadGenerationProps?: MainLeadGenerationProps
  customerReviewsProps?: CustomerReviewsProps
  footerProps?: FooterProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ArticlesPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      <Wrapper row tag="section">
        <Content>
          {context.articleInfoProps ? (
            <MainTitle>{context.articleInfoProps.title}</MainTitle>
          ) : null}
          {context.articleInfoProps ? (
            <Subtitle>
              Pubblicato il <span>{context.articleInfoProps.created}</span> da{' '}
              <span>{context.articleInfoProps.editor}</span>
            </Subtitle>
          ) : null}
          {context.paragraphsProps ? (
            <Paragraphs {...context.paragraphsProps} />
          ) : null}
        </Content>
        <Sidebar>
          <Title>Condividi</Title>
          {context.articleInfoProps ? (
            <SocialShare row>
              <EmailShareButton
                url={`www.simplebooking.travel/it/blog/${context.articleInfoProps.slug}`}
              >
                <EmailIcon size="30" />
              </EmailShareButton>
              <FacebookShareButton
                url={`www.simplebooking.travel/it/blog/${context.articleInfoProps.slug}`}
              >
                <FacebookIcon size="30" />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`www.simplebooking.travel/it/blog/${context.articleInfoProps.slug}`}
              >
                <LinkedinIcon size="30" />
              </LinkedinShareButton>
            </SocialShare>
          ) : null}
          {context.recentArticlesProps ? (
            <RecentArticles {...context.recentArticlesProps} />
          ) : null}
        </Sidebar>
      </Wrapper>
      {context.customerReviewsProps ? (
        <CustomerReviews
          languageCode={pageContext.languageCode}
          {...context.customerReviewsProps}
        />
      ) : null}
      {context.mainLeadGenerationProps ? (
        <MainLeadGeneration
          languageCode={pageContext.languageCode}
          {...context.mainLeadGenerationProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``

const PageTitle = styled.h1`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  background-color: black;
  color: ${({ theme }) => theme.colors.variants.base};
  span {
    display: block;
    max-width: 1400px;
    margin: auto;
    padding: 2rem;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 4rem 2rem;

  @media all and (max-width: 767px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  width: 70%;
  border-right: 1px solid #dedede;
  padding-right: 2rem;

  @media all and (max-width: 767px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #dedede;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    padding-right: 0;
  }
`

const Sidebar = styled.div`
  width: 25%;
  padding-left: 2rem;

  @media all and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`

const SocialShare = styled(FlexBox)`
  border-bottom: 1px solid #dedede;
  margin-top: 1rem;
  padding-bottom: 2rem;
  button {
    margin-right: 0.75rem;
  }
`

const MainTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  text-align: left;
  font-weight: 700;
`

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.variants.default};
  margin: 0.5rem 0 1.125rem 0;
  span {
    color: black;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
`
